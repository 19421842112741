import baseComponent from "@/scripts/baseComponent";
import usersApi from '@/api/common/users';

export default {

    extends: baseComponent,

    props: {
        element: {
            type: Object,
            default: null
        },
    },
    watch: {
	    element(newData) {
		    this.getAdditionalInfo1();
	    },
    },
    data() {
        return {
            isLoading: false,
            currentThresholdImage: 0,
            newThresholdImage: 0,
            confirmText: '',
            currentStorageSize: 0,
            newStorageSize: 100,
        };
    },

    computed: {

    },

    created() {
        this.getAdditionalInfo1();
    },

    methods: {
        convertToGb(byteValue) {
            return byteValue / (1024 * 1024 * 1024);
        },
        getAdditionalInfo1() {
            if (this.element.id !== null) {
                usersApi
                    .getAdditionalInfo1(this.element.id)
                    .then((response) => {
                        if (response.data && response.data.result === 0) {
                            this.$set(this, "currentThresholdImage", response.data.data.imageThreshold);
                            this.$set(this, "newThresholdImage", response.data.data.imageThreshold);
                            this.$set(this, "currentStorageSize", this.convertToGb(response.data.data.storageThreshold));
                            this.$set(this, "newStorageSize", this.convertToGb(response.data.data.storageThreshold));
                        } else {
                            if (response.data.message !== null && response.data.message !== "") {
                                console.error(response.data.message || this.$lang.common.error);
                            }
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        },
        // Change settings user
        changeSettingsUser() {
            this.isLoading = true;
            let request = {
                userId: this.element.id,
                imageThreshold: this.newThresholdImage,
                maxStorageZInGB: parseInt(this.newStorageSize),
                deviceInformation: JSON.stringify(this.getInformationClient()),
            };
            usersApi.changeSettingsUser(request)
                .then((res) => {
                    try {
                        this.showSuccessToast(`Successful user setting change !`);
                        setTimeout(() => {
                            this.closePopup('user-settings');
                        }, 500);
                    } catch (error) {
                        console.log("changeSettingsUser -> error", error)
                        // this.$store.dispatch("common/showUnkownError", error, "Error");
                    }
                    setTimeout(() => {
                        this.isLoading = false;
                    }, 500);
                })
                .catch(error => {
                    try {
                        if (error.name === 'Error') {
                            // if (this.$route.path !== '/login') {
                            //     setTimeout(() => {
                            //         location.href = "/login";
                            //     }, 2000);
                            // }
                        } else {
                            console.log("changeSettingsUser -> error", error)
                        }
                    } catch (error) {
                        console.log("getData -> error", error)
                        // this.$store.dispatch("common/showUnkownError", error, "Error");
                    }
                    setTimeout(() => {
                        this.isLoading = false;
                    }, 500);
                });
        },
    }
} 